@font-face {
  font-family: 'Jalnan';
  // font-weight: 400;
  font-style: normal;
  src: url('../../public/fonts/JalnanGothic.otf') format('openType');
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 400;
  font-style: normal;
  src: url('../../public/fonts/Pretendard-Regular.subset.woff') format('woff');
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 500;
  font-style: normal;
  src: url('../../public/fonts/Pretendard-Medium.subset.woff') format('woff');
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 600;
  font-style: normal;
  src: url('../../public/fonts/Pretendard-SemiBold.subset.woff') format('woff');
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 700;
  font-style: normal;
  src: url('../../public/fonts/Pretendard-Bold.subset.woff') format('woff');
}

.frame{
  position: relative;
  // overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
  max-width: 430px;
  min-height: 100vh;
  background-color: white;
  font-family: 'Pretendard', pretendard, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

.frame::-webkit-scrollbar-track{
  background-color: red;
}

.logo24{
  z-index: 2;

  width: 150px;
  object-fit: cover;
}

.sec20{
  display: flex;
  flex-direction: column;
  padding: 64px 20px;
  overflow: hidden;
  max-height: 640px;
  height: 100vh;

  position: relative;
}

.sec0{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 48px;
  padding: 64px 0;

  background-color: #111;
}

.title-wrap{
  z-index: 2;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;

  margin-top: 80px;
}

.title-label{
  font-size: 15px;
  line-height: 23px;
  color: rgba(255, 255, 255, .8);
}

.title-name{
  font-size: 36px;
  line-height: 48px;
  color: white;
  font-family: 'Jalnan', Jalnan, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

.title-infos{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.title-info{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 6px;
}

.title-info-label{
  display: flex;
  flex-shrink: 0;

  font-size: 17px;
  line-height: 25px;
  color: white;
  font-weight: 600;
}

.title-info-data{
  font-size: 17px;
  line-height: 25px;
  color: rgba(255, 255, 255, .8);
  font-weight: 400;
}

.title-sec-img{
  position: absolute;
  z-index: 1;
  right: -350px;
  top: -100px;

  width: 700px;
  height: 700px;
}

.bottom-btn-wrap{
  position: fixed;
  bottom: 0;
  left: 0;

  width: 100%;
  padding: 0 20px 20px 20px;
}

.button-primary{
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  height: 53px;
  width: 100%;
  background: #20363B;
  border-radius: 8px;

  font-size: 17px;
  font-weight: 700;
  line-height: 25px;
  color: #72FBA0;
}

.button-primary.disabled{
  opacity: .3;
  cursor: not-allowed
}

.yellow{
  color: #FFB200;
}

.title28{
  font-size: 28px;
  line-height: 36px;
  color: white;
  font-family: 'Jalnan', Jalnan, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  text-align: center;
}

.comment-wrap.forward{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  padding-right: 20px;
}

.comment-wrap.reverse{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  padding-left: 20px;
}

.comment-profile{
  width: 56px;
  height: 56px;
  object-fit: cover;
}

.comment-container.forward{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;

  padding: 24px 20px;
  border-radius: 0 16px 16px 0;

  background-color: #1d1d1d;

  font-size: 17px;
  line-height: 25px;
  color: rgba(255, 255, 255, .8);
}

.comment-container.reverse{
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  gap: 12px;

  padding: 24px 20px;
  border-radius: 16px 0px 0px 16px;

  background-color: #1d1d1d;

  font-size: 17px;
  line-height: 25px;
  color: rgba(255, 255, 255, .8);
}

.comments-wrap{
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 24px;
}

.underline{
  text-decoration: underline;
}

.white{
  color: #fff;
}

.dots{
  width: 6px;
}

.text-group{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  padding: 0 20px;
}

.text-group-title{
  font-size: 24px;
  line-height: 32px;
  color: white;
  font-family: 'Jalnan', Jalnan, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  text-align: center;
}

.text-group-text{
  font-size: 17px;
  line-height: 25px;
  color: rgba(255, 255, 255, .8);
  text-align: center;
}

.qa-container{
  display: flex; 
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;

  padding: 16px 20px;
}

.qa-head{
  display: flex; 
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
}

.qa-label{
  font-size: 15px;
  line-height: 17px;
  font-weight: 700;
  color: #000;

  padding: 4px 6px;
  border-radius: 10px;
  background: linear-gradient(15deg, #FFCC56 ,#FFB200);
}

.qa-sec-title{
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  padding: 20px;

  font-size: 24px;
  line-height: 28px;
  color: white;
  font-family: 'Jalnan', Jalnan, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

.qa-title{
  font-size: 20px;
  line-height: 28px;
  color: white;
  font-family: 'Jalnan', Jalnan, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

.qa-text{
  font-size: 17px;
  line-height: 25px;
  color: rgba(255, 255, 255, .8);
}

.sec-qa{
  padding: 64px 0;
}

.sec-form{
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 36px;

  padding: 48px 20px 84px 20px;
  border-radius: 24px 24px 0 0;
  background-color: white;
  box-shadow: 0 0 96px rgba(21, 37, 84, 0.1);
}

.form-title{
  font-size: 24px;
  line-height: 28px;
  color: #3B3D47;
  font-weight: 700;
  padding-bottom: 12px;
}

.text-input-wrap{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 9px;
}

.input-label{
  font-size: 15px;
  font-weight: 500;
  color: #3B3D47;
  line-height: 21px;
}

.text-input{
  background-color: #FAFAFC;
  font-size: 17px;
  font-weight: 500;
  color: black;
  line-height: 25px;

  outline: none;

  height: 49px;
  border-radius: 8px;
  border: 1px solid #DADDE5;

  padding: 12px 16px;
  width: 100%;

   &:focus{
    border: 1px solid black;
  }
}

.text-input::placeholder{
  color: #7F828F;
}

.radio-wrap{
  display: flex;
  flex-direction: column;
  align-items: stretch;

  width: 100%;
  border: .5px solid #404040;
  border-radius: 8px;
}

.radio-item{
  cursor: pointer;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;

  padding: 0 16px;
  height: 49px
}

.radio-icon{
  display: flex;
  align-items: center;
  justify-content: center;

  width: 17px;
  height: 17px;
  border-radius: 9px;
  border: 1px solid white;

  opacity: .2;

  .radio-icon-inner{
    display: none;

    width: 9px;
    height: 9px;
    border-radius: 5px;
    background-color: white;
  }
}

.radio-icon.active{
  opacity: 1;

  .radio-icon-inner{
    display: flex;
  }
}

.radio-name{
  font-size: 17px;
  font-weight: 500;
  color: white;
  line-height: 25px;
}

.personal-info-wrap{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;

  margin-top: 24px;
}

.personal-info-text-wrap{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}

.personal-info-text-title{
  font-size: 17px;
  font-weight: 700;
  color: #3B3D47;
  line-height: 25px;
}

.personal-info-text{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
}

.personal-info-text-group{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 5px;
}

.personal-info-text-number{
  font-size: 15px;
  font-weight: 400;
  color: #5C5F6B;
  line-height: 21px;
}

.personal-info-text-data{
  font-size: 15px;
  font-weight: 400;
  color: #5C5F6B;
  line-height: 21px;
}

.semi{
  font-weight: 600;
}

.w80{
  color: #3B3D47;
}

.com-scroll::-webkit-scrollbar {
  display: none;
}

.com-scroll{
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

#date:focus {
  outline: 0;
}

.personal-agree-btn{
  cursor: pointer;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  width: 100%;
  padding: 12px;
  border: 1px solid #DADDE5;
  border-radius: 8px;

  .personal-agree-icon{
    // opacity: .2;

    width: 23px;
    height: 23px;
    object-fit: cover;
  }
}

.personal-agree-btn.active{
  border: 1px solid black;

  .personal-agree-icon{
    opacity: 1;

    width: 23px;
    height: 23px;
    object-fit: cover;
  }
}

.personal-agree-text{
  font-size: 15px;
  line-height: 21px;
  font-weight: 400;
  color: black;
}

.input-error{
  font-size: 15px;
  font-weight: 500;
  color: #f04400;
  line-height: 21px;
}

.footer{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;

  width: 100%;
  padding: 24px 0;
}

.footer-text{
  font-size: 13px;
  line-height: 19px;
  font-weight: 400;
  color: #5C5F6B;
  text-align: center;
}

.complete-info{
  padding: 24px 20px;
  background-color: #1d1d1d;
  border-radius: 12px;
}

.sec-complete{
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 48px 20px;
  gap: 24px;
}

.complete-title{
  font-size: 20px;
  font-weight: 700;
  color: white;
  line-height: 24px;
}

.alarm-text{
  font-size: 15px;
  font-weight: 500;
  line-height: 21px;
  color: rgba(255, 255, 255, .8);
  text-align: center;
}